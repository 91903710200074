import React, {useState} from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {ADD_TAG, DELETE_TAG, GET_RECIPE_FULL, SAVE_COMMENT} from "../utilities/Queries";
import "./FullRecipe.css";
import {Chip, CircularProgress, DialogContent} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RedesignFullRecipeMobile from "./RedesignFullRecipeMobile";
import RedesignFullRecipeLarge from "./RedesignFullRecipeLarge";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {Add, AddCircleOutline, Clear, Close, Done, Edit, LocalOffer, RemoveCircleOutline} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {client} from "../ApolloLayer";
import {getPrettyIngredientName} from "../utilities/Utilities";

export default function FullRecipeContent(props): JSX.Element {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    let getRecipeFullVariables = props.getRecipeFullVariables;
    const {loading, error, data} = useQuery(GET_RECIPE_FULL, {
        variables: getRecipeFullVariables,
        client: client
    });
    const [deleteTag] = useMutation(DELETE_TAG, {
        client: client
    });
    const [addTag] = useMutation(ADD_TAG, {
        client: client
    });
    const [saveComment] = useMutation(SAVE_COMMENT, {
        client: client
    });

    if (loading) {
        return <div style={{minHeight: "inherit", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <CircularProgress color="secondary"/>
        </div>;
    }
    if (error) {
        console.log(error);
        return <div>{error.message}</div>;
    }
    let recipe = data.recipe;
    let allTags = data.tags;

    if (isLargeScreen) {
        return <RedesignFullRecipeLarge recipe={recipe}
                                        isLoggedIn={props.isLoggedIn}
                                        deleteTag={deleteTag}
                                        addTag={addTag}
                                        saveComment={saveComment}
                                        getRecipeFullVariables={getRecipeFullVariables}
                                        allTags={allTags}/>;
    } else {
        return <RedesignFullRecipeMobile recipe={recipe}
                                         isLoggedIn={props.isLoggedIn}
                                         deleteTag={deleteTag}
                                         addTag={addTag}
                                         saveComment={saveComment}
                                         getRecipeFullVariables={getRecipeFullVariables}
                                         allTags={allTags}/>;
    }
}

export function renderInstructions(recipe, classes) {
    let instructions = recipe.instructions;
    let split = instructions.split(".");
    let removed: number[] = [];
    for (let i = 0; i < split.length; i++) {
        let instruction = split[i];
        if (instruction.toLowerCase().endsWith(" st")) {
            split[i] += "." + split[i + 1];
            i++;
            removed.push(i);
        }
    }
    removed.forEach((removedNumber) => {
        split.splice(removedNumber, 1);
    });
    return <div className="flex-column-center">
        <Typography color="secondary" variant="h6">{"Instructions"}</Typography>
        <div>
            {split.map((instruction, i) => {
                return renderInstruction(instruction, i);
            })}
        </div>
    </div>;
}

function renderInstruction(instruction, i) {
    if (instruction.trim() === "") {
        return undefined;
    }
    const [completed, setCompleted] = useState(false);

    return <div key={"instr-" + instruction + "-" + i} className={i === 0 ? "first-instruction" : "instruction"}>
        <div className="number">{i + 1}</div>
        <Typography variant="body2" onClick={() => {
            setCompleted(!completed);
        }} style={completed ? {textDecoration: "line-through", cursor: "pointer"} : {cursor: "pointer"}}>
            {instruction + "."}
        </Typography>
    </div>;
}

export function renderComments(recipe, classes, saveComment, getRecipeFullVariables, isMobile, isLoggedIn) {
    if (!isLoggedIn) {
        return undefined;
    }
    const [isOpen, setOpen] = useState(false);
    const comment = recipe.comment ? recipe.comment : "";
    return <div className="flex-column-center">
        <div className="flex-row-center">
            <Typography color="secondary" variant="h6">{"Comments"}</Typography>
            <IconButton color="secondary" aria-label="Edit"
                        onClick={() => {
                            setOpen(true);
                        }}>
                <Edit/>
            </IconButton>
        </div>
        <div>
            {comment.split("\n").map((line, i) => {
                return <p className="MuiTypography-body2" key={i}>{line}</p>;
            })}
        </div>
        {renderCommentsDialog(isOpen, setOpen, recipe, classes, saveComment, getRecipeFullVariables, isMobile)}
    </div>;
}

function renderCommentsDialog(isOpen, setOpen, recipe, classes, saveComment, getRecipeFullVariables, isMobile) {
    const [newCommentText, setNewCommentText] = useState(recipe.comment);

    let content = (
        <TextField color="secondary"
                   multiline
                   defaultValue={recipe.comment}
                   style={{width: "inherit"}}
                   onChange={(event) => {
                       setNewCommentText(event.target.value);
                   }}
        />
    );
    let saveButton = (
        <Button onClick={() => {
            saveComment({
                variables: {recipeId: recipe.id, comment: newCommentText}, refetchQueries: [{
                    query: GET_RECIPE_FULL,
                    variables: getRecipeFullVariables
                }],
                optimisticResponse: {
                    __typename: "Mutation",
                    saveComment: {
                        __typename: "Recipe",
                        id: recipe.id,
                        comment: newCommentText
                    }
                }
            });
            setOpen(false);
        }} color={isMobile ? "primary" : "secondary"} autoFocus>
            Save
        </Button>
    );
    if (isMobile) {
        return <Dialog fullScreen open={isOpen}>
            <AppBar color="secondary" style={{position: "relative", marginBottom: "10px"}}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => {
                        setOpen(false);
                        setNewCommentText(recipe.comment);
                    }} aria-label="close">
                        <Close color="primary"/>
                    </IconButton>
                    <Typography color="primary" variant="h6" className={classes.title}>
                        Edit comment
                    </Typography>
                    {saveButton}
                </Toolbar>
            </AppBar>
            {content}
        </Dialog>;
    } else {
        return <Dialog open={isOpen}>
            <DialogTitle id="simple-dialog-title">Edit comment</DialogTitle>
            <DialogContent className={classes.commentsDialog}>
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                    setNewCommentText(recipe.comment);
                }}>
                    Cancel
                </Button>
                {saveButton}
            </DialogActions>
        </Dialog>;
    }
}

export function renderTags(recipe, deleteTag, addTag, getRecipeFullVariables, classes, allTags, isMobile, isLoggedIn) {
    if (!isLoggedIn) {
        return undefined;
    }
    const [isOpen, setOpen] = useState(false);

    return <div className="flex-column-center">
        <div className="flex-row-center">
            <Typography color="secondary" variant="h6">{"Tags"}</Typography>
            <IconButton color="secondary" aria-label="Add"
                        onClick={() => {
                            setOpen(true);
                        }}>
                <Add/>
            </IconButton>
        </div>
        <div className="tags">
            {recipe.tags.map((tag) => {
                return renderTag(recipe, tag, deleteTag, addTag, getRecipeFullVariables, classes);
            })}
        </div>
        {renderTagDialog(isOpen, setOpen, recipe, classes, allTags, addTag, getRecipeFullVariables, isMobile)}
    </div>;
}

function renderTag(recipe, tag, deleteTag, addTag, getRecipeFullVariables, classes) {
    return <Chip
        key={"chip-" + tag.name}
        label={tag.name}
        variant="outlined"
        color="secondary"
        className={classes.chip}
        onDelete={() => {
            let newTags = recipe.tags
                .filter((innerTag) => {
                    return tag.id !== innerTag.id;
                })
                .map((tag) => {
                    return {
                        __typename: "Tag",
                        id: tag.id,
                        name: tag.name
                    };
                });
            deleteTag({
                variables: {recipeId: recipe.id, tag: tag.name}, refetchQueries: [{
                    query: GET_RECIPE_FULL,
                    variables: getRecipeFullVariables
                }],
                optimisticResponse: {
                    __typename: "Mutation",
                    deleteTag: {
                        __typename: "Recipe",
                        id: recipe.id,
                        tags: newTags
                    }
                }
            });
        }}
    />;
}

function renderAddNewTag(newTagText, setNewTagText, classes, saveNewTag) {
    return <>
        {newTagText === null ?
            <ListItem button onClick={() => {
                setNewTagText("");
            }}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <Add/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add new tag"/>
            </ListItem> :
            <ListItem>
                <ListItemAvatar>
                    <IconButton aria-label="Cancel" onClick={() => {
                        setNewTagText(null);
                    }}>
                        <Clear color="secondary"/>
                    </IconButton>
                </ListItemAvatar>
                <ListItemText primary={
                    <TextField autoFocus
                               defaultValue={newTagText}
                               onChange={(event) => {
                                   setNewTagText(event.target.value);
                               }}
                               onKeyDown={(event) => {
                                   if (event.keyCode === 13) {
                                       saveNewTag();
                                       event.preventDefault();
                                   }
                               }}
                               color="secondary"/>}/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="Done" onClick={() => {
                        saveNewTag();
                    }}>
                        <Done color="secondary"/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>}
    </>;
}

function renderTagDialog(isOpen, setOpen, recipe, classes, allTags, addTag, getRecipeFullVariables, isMobile) {
    const [newTagText, setNewTagText] = useState(null);
    let saveNewTag = () => {
        if (newTagText !== null && newTagText !== "") {
            let newTags = recipe.tags
                .map((tag) => {
                    return {
                        __typename: "Tag",
                        id: tag.id,
                        name: tag.name
                    };
                });
            newTags.push(
                {
                    __typename: "Tag",
                    id: newTagText,
                    name: newTagText
                }
            );
            addTag({
                variables: {recipeId: recipe.id, tag: newTagText}, refetchQueries: [{
                    query: GET_RECIPE_FULL,
                    variables: getRecipeFullVariables
                }],
                optimisticResponse: {
                    __typename: "Mutation",
                    addTag: {
                        __typename: "Recipe",
                        id: recipe.id,
                        tags: newTags
                    }
                }
            });
            setNewTagText(null);
            setOpen(false);
        } else {
            setNewTagText(null);
        }
    };
    let content = (
        <List>
            {renderAddNewTag(newTagText, setNewTagText, classes, saveNewTag)}
            {allTags.filter(tag => {
                return recipe.tags.findIndex(recipeTag => recipeTag.name === tag.name) === -1;
            }).map((tag) => (
                <ListItem button onClick={() => {
                    let newTags = recipe.tags
                        .map((tag) => {
                            return {
                                __typename: "Tag",
                                id: tag.id,
                                name: tag.name
                            };
                        });
                    newTags.push(
                        {
                            __typename: "Tag",
                            id: tag.name,
                            name: tag.name
                        }
                    );
                    addTag({
                        variables: {recipeId: recipe.id, tag: tag.name}, refetchQueries: [{
                            query: GET_RECIPE_FULL,
                            variables: getRecipeFullVariables
                        }],
                        optimisticResponse: {
                            __typename: "Mutation",
                            addTag: {
                                __typename: "Recipe",
                                id: recipe.id,
                                tags: newTags
                            }
                        }
                    });
                    setOpen(false);
                }} key={tag.name}>
                    <ListItemAvatar>
                        <Avatar className={classes.avatarPersonal}>
                            <LocalOffer/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={tag.name}/>
                </ListItem>
            ))}
        </List>
    );

    if (isMobile) {
        return <Dialog fullScreen open={isOpen}>
            <AppBar color="secondary" style={{position: "relative"}}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => {
                        setOpen(false);
                    }} aria-label="close">
                        <Close color="primary"/>
                    </IconButton>
                    <Typography color="primary" variant="h6" className={classes.title}>
                        {"Add tags"}
                    </Typography>
                </Toolbar>
            </AppBar>
            {content}
        </Dialog>;
    } else {
        return <Dialog onClose={() => {
            setOpen(false);
        }} open={isOpen}>
            <DialogTitle id="simple-dialog-title">Add tags</DialogTitle>
            {content}
        </Dialog>;
    }
}

export function renderIngredients(recipe, classes, isMobile) {
    const [howMany, setHowMany] = useState(recipe.servings);
    const differenceBetweenDefaultServingsAndSelected = howMany / recipe.servings;
    return <div className={isMobile ? "ingredients-mobile" : "ingredients-full"}>
        <div className="flex-column-center">
            <Typography color="secondary" variant="h6" className={classes.ingredients}>{"Ingredients"}</Typography>
            {renderIngredientMultiplier(recipe, howMany, setHowMany)}
        </div>
        {recipe.ingredients.map((ingredient, i) => {
            return renderIngredient(recipe, ingredient, i !== 0, howMany, classes);
        })}
        <Typography variant="body2"
                    className={classes.ingredientsExtras}>{"Garnish: " + (recipe.garnish.charAt(0).toUpperCase() + recipe.garnish.substr(1))}</Typography>
        <Typography variant="body2"
                    className={classes.ingredientsExtras}>{"Glass: " + (recipe.glass.charAt(0).toUpperCase() + recipe.glass.substr(1))}</Typography>
        <Typography variant="body2"
                    className={classes.ingredientsExtras}>{"Total volume: " + (recipe.postDilutionVolume * differenceBetweenDefaultServingsAndSelected / 30).toFixed(1) + " oz."}</Typography>
    </div>;
}

function renderIngredientMultiplier(recipe, howMany, setHowMany) {
    return <div className="ingredient-multiplier">
        <IconButton color="secondary" aria-label="Less" disabled={howMany === 1}
                    onClick={() => {
                        setHowMany(howMany - 1);
                    }}>
            <RemoveCircleOutline/>
        </IconButton>
        <Typography color="secondary"
                    variant="body1">{howMany + " " + (howMany === 1 ? "Serving" : "Servings")}</Typography>
        <IconButton color="secondary" aria-label="More" onClick={() => {
            setHowMany(howMany + 1);
        }}>
            <AddCircleOutline/>
        </IconButton>
    </div>;
}

function calculateAmount(recipe, ingredient, howMany) {
    if (ingredient.amount) {
        let servings = recipe.servings;
        if (servings === howMany) {
            return ingredient.amount;
        }
        return parseFloat((ingredient.amount * (howMany / servings)).toFixed(2));
    }
    return "";
}

function renderIngredient(recipe, ingredient, renderDividerLine, howMany, classes) {
    const [completed, setCompleted] = useState(false);

    let amount = calculateAmount(recipe, ingredient, howMany);
    let amountWithMeasurement;
    if (amount === 0) {
        amountWithMeasurement = ingredient.typeOfMeasurement;
    } else if (!ingredient.typeOfMeasurement || ingredient.typeOfMeasurement === "null") {
        amountWithMeasurement = amount;
    } else {
        amountWithMeasurement = amount + " " + ingredient.typeOfMeasurement;
    }
    return <div key={ingredient.id}
                onClick={() => {
                    setCompleted(!completed);
                }}
                style={completed ? {textDecoration: "line-through"} : undefined}
                className={renderDividerLine ? "ingredient-with-divider" : "ingredient-without-divider"}>
        <Typography key={ingredient.id} variant="body2" className={classes.ingredientAmount}>
            {amountWithMeasurement}
        </Typography>
        <Typography variant="body2">
            {getPrettyIngredientName(ingredient.name) + formatBottles(ingredient.bottles)}
        </Typography>
    </div>;
}

function formatBottles(bottles) {
    if (bottles.length === 0) {
        return "";
    }
    let returnString;
    if (bottles.length === 1) {
        returnString = bottles[0];
    } else {
        let firstElements = bottles.slice(0, bottles.length - 1);
        returnString = firstElements.join(", ");
        returnString += " or " + bottles[bottles.length - 1];
    }
    return " (" + returnString + ")";
}