import React from "react";
import {Chip} from "@material-ui/core";
import {client} from "../../ApolloLayer";

export default function ChipFilterComponent(props) {
    const {chips, selectedChips, typeName} = props;

    return (
        <div className="filter-chips">
            {chips.map((chip) => {
                let isSelected = selectedChips.includes(chip);
                return (
                    <Chip
                        key={"chip-" + chip}
                        label={chip}
                        variant={isSelected ? "default" : "outlined"}
                        color="secondary"
                        style={isSelected ? {margin: "5px", color: "white"} : {margin: "5px"}}
                        onClick={() => {
                            let newSelectedChips = calculateSelectedChips(chip, selectedChips);

                            client.cache.modify({
                                id: `ROOT_QUERY`,
                                fields: {
                                    [typeName](existingData = {}) {
                                        return {
                                            ...existingData,
                                            data: newSelectedChips,
                                            key: Math.random(),
                                        };
                                    }
                                }
                            });
                        }}
                    />
                );
            })}
        </div>
    );
}

function calculateSelectedChips(chip, selectedChips) {
    if (selectedChips.includes(chip)) {
        return selectedChips.filter((selectedChip) => selectedChip !== chip);
    } else {
        return [...selectedChips, chip];
    }
}