import React, {Component} from "react";
import {Chip} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {getPrettyIngredientName} from "../utilities/Utilities";

class AutoCompleteField extends Component {
    handleChange = (searchChips, oldSearchChips) => {
        let newSearchChips = searchChips.map((searchChip) => {
            let oldSearchChip = oldSearchChips.find((chip) => {
                return chip.name === searchChip.text;
            });
            let isInverted = oldSearchChip ? oldSearchChip.inverted : false;
            return {
                id: searchChip.text,
                name: searchChip.text,
                type: searchChip.type,
                inverted: isInverted,
                minMaxAmount: {
                    minimum: oldSearchChip ? oldSearchChip.minMaxAmount.minimum : null,
                    maximum: oldSearchChip ? oldSearchChip.minMaxAmount.maximum : null,
                    typeOfMeasurement: oldSearchChip ? oldSearchChip.minMaxAmount.typeOfMeasurement : "oz",
                    __typename: "minMaxAmount"
                },
                __typename: "searchTerm"
            };
        });
        this.props.onChange(newSearchChips);
    };

    handleButtonPress(event, chipIndex) {
        event.persist();
        this.buttonPressTimer = setTimeout((event, chipIndex) => {
            this.props.handleRightClick(event, chipIndex);
        }, 300, event, chipIndex);
    }

    handleButtonRelease() {
        clearTimeout(this.buttonPressTimer);
    }

    render() {
        const {dataSource, searchChips} = this.props;
        return <Autocomplete
            multiple
            id="autocomplete-recipe"
            options={dataSource}
            getOptionLabel={(option) => {
                if (option.type === "Ingredient" || option.type === "Category") {
                    return getPrettyIngredientName(option.text);
                } else return option.text;
            }}
            filterSelectedOptions
            onChange={(event, newSearchResults) => {
                this.handleChange(newSearchResults, searchChips);
            }}
            value={searchChips.map((chip) => {
                return {
                    text: chip.name,
                    type: chip.type,
                    __typename: chip.__typename
                };
            })}
            getOptionSelected={(option, value) => {
                return option.text === value.text && option.type === value.type;
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    let matchingSearchIndex = searchChips.findIndex(chip => {
                        return chip.name === option.text;
                    });
                    let matchingSearchChip = matchingSearchIndex !== -1 ? searchChips[matchingSearchIndex] : null;
                    let isInverted = matchingSearchChip ? matchingSearchChip.inverted : false;
                    let text = option.text;
                    if (option.type === "Ingredient" || option.type === "Category") {
                        text = getPrettyIngredientName(option.text);
                    }
                    return <div key={"div-" + index}>
                        <Chip color={isInverted ? "default" : "secondary"}
                              variant={isInverted ? "default" : "outlined"}
                              style={isInverted ? {backgroundColor: "red", color: "white"} : undefined}
                              label={text}
                              onClick={() => {
                                  let newSearchChips = JSON.parse(JSON.stringify(searchChips));
                                  let searchChip = newSearchChips[matchingSearchIndex];
                                  if (searchChip.type !== "Recipe") {
                                      searchChip.inverted = !searchChip.inverted;
                                      this.props.onChange(newSearchChips);
                                  }
                              }}
                              onContextMenu={(event) => {
                                  this.props.handleRightClick(event, index);
                              }}
                              onTouchStart={(event) => {
                                  this.handleButtonPress(event, index);
                              }}
                              onTouchEnd={(event) => {
                                  this.handleButtonRelease();
                              }}
                              onMouseDown={(event) => {
                                  this.handleButtonPress(event, index);
                              }}
                              onMouseUp={(event) => {
                                  this.handleButtonRelease();
                              }}
                              onMouseLeave={(event) => {
                                  this.handleButtonRelease();
                              }}
                              {...getTagProps({index})} />
                    </div>;
                })
            }
            style={{width: "100%"}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    color="secondary"
                    placeholder="Search for ingredients, tags or specific recipes"
                />
            )}
        />;
    }
}

export default AutoCompleteField;