import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {client} from "../ApolloLayer";
import {
    ADD_MENU_ITEM,
    GET_COCKTAIL_MENU,
    SAVE_CATEGORY_DESCRIPTIONS,
    SAVE_DESCRIPTION,
    SAVE_MENU_ITEMS_ORDER,
    SAVE_TITLE,
} from "./CocktailMenuQueries";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./CocktailMenu.css";
import CocktailMenuSortItem from "./CocktailMenuSortItem";
import List from "@material-ui/core/List";
import RootRef from "@material-ui/core/RootRef";
import {DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";
import QRCode from "react-qr-code";
import CocktailMenuRenderer from "./renderer/CocktailMenuRenderer";
import {Edit, Save} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import RecipeAutoCompleteSearchField from "../utilities/RecipeAutoCompleteSearchField";

interface CocktailMenu {
    id: string;
    name: string;
    description: string | null;
    categoryDescriptions: { category: string; description: string }[];
    cocktailMenuItems: { id: string }[];
}

interface QueryData {
    cocktailMenu: CocktailMenu;
}

interface QueryVars {
    id: string;
}

interface MutationVars {
    menuId: string;
    text?: string;
    items?: string[];
    categoryDescriptions?: { category: string; description: string }[];
    id?: string;
}

const reorder = <T, >(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getListStyle = (isDraggingOver: boolean) => ({
    // Optional styling for drag and drop
});

function onDragEnd(
    result: DropResult,
    data: QueryData,
    saveOrder: (options: { variables: MutationVars }) => void
) {
    const items = data.cocktailMenu.cocktailMenuItems;
    if (!result.destination) {
        return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);
    saveOrder({
        variables: {
            menuId: data.cocktailMenu.id,
            items: newItems.map((item) => item.id),
        },
    });
}

export default function CocktailMenuEditor(): JSX.Element {
    const [titleOpen, setTitleOpen] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [descriptionOpen, setDescriptionOpen] = useState(false);
    const [descriptionText, setDescriptionText] = useState("");
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [categoryState, setCategoryState] = useState<
        { category: string; description: string }[] | null
    >(null);

    const location = useLocation();
    const pathName = location.pathname;
    const split = pathName.split("/");
    const cocktailMenuId = split[2];

    const {loading, error, data} = useQuery<QueryData, QueryVars>(
        GET_COCKTAIL_MENU,
        {
            variables: {id: cocktailMenuId},
            client,
        }
    );

    const [saveOrder] = useMutation<void, MutationVars>(SAVE_MENU_ITEMS_ORDER, {
        client,
    });
    const [addItem] = useMutation<void, MutationVars>(ADD_MENU_ITEM, {client});
    const [saveTitle] = useMutation<void, MutationVars>(SAVE_TITLE, {client});
    const [saveDescription] = useMutation<void, MutationVars>(SAVE_DESCRIPTION, {
        client,
    });
    const [saveCategory] = useMutation<void, MutationVars>(
        SAVE_CATEGORY_DESCRIPTIONS,
        {client}
    );

    if (loading) {
        return <CircularProgress color="secondary"/>;
    }
    if (error || !data) {
        return <div>Error loading cocktail menu.</div>;
    }

    return (
        <div className="flex-column-center">
            <div className="title-and-qr">
                <div className="title-and-description-outer">
                    <div className="title-and-description-inner">
                        {renderTitle(
                            data.cocktailMenu,
                            titleOpen,
                            setTitleOpen,
                            titleText,
                            setTitleText,
                            saveTitle
                        )}
                        {renderDescription(
                            data.cocktailMenu,
                            descriptionOpen,
                            setDescriptionOpen,
                            descriptionText,
                            setDescriptionText,
                            saveDescription
                        )}
                        {renderCategoryDescriptions(
                            data.cocktailMenu,
                            categoryOpen,
                            setCategoryOpen,
                            categoryState,
                            setCategoryState,
                            saveCategory
                        )}
                    </div>
                </div>
                <div style={{marginRight: "10px"}}>
                    <Link
                        to={`/menu/${cocktailMenuId}`}
                        style={{textDecoration: "none", color: "inherit"}}
                    >
                        <QRCode
                            size={75}
                            value={`${window.location.href.split("/#/")[0]}/#/menu/${cocktailMenuId}`}
                        />
                    </Link>
                </div>
            </div>
            <div className="cocktail-items-outer-box">
                <div className="cocktail-menu-width">
                    <div className="items-list">
                        <RecipeAutoCompleteSearchField
                            setCurrentRecipe={(recipeId) => {
                                addItem({
                                    variables: {
                                        menuId: data.cocktailMenu.id,
                                        id: recipeId,
                                    },
                                });
                            }}
                            excludedIds={data.cocktailMenu.cocktailMenuItems.map(
                                (item) => item.id.split(`${data.cocktailMenu.id}:`)[1]
                            )}
                        />
                        <DragDropContext
                            onDragEnd={(result) => onDragEnd(result, data, saveOrder)}
                        >
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    // @ts-ignore
                                    <RootRef rootRef={provided.innerRef}>
                                        <List style={getListStyle(snapshot.isDraggingOver)}>
                                            {data.cocktailMenu.cocktailMenuItems.map((item, index) => (
                                                <CocktailMenuSortItem
                                                    key={index}
                                                    item={item}
                                                    menu={data.cocktailMenu}
                                                    index={index}
                                                />
                                            ))}
                                            {provided.placeholder}
                                        </List>
                                    </RootRef>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <CocktailMenuRenderer menu={data.cocktailMenu}/>
                </div>
            </div>
        </div>
    );
}

function renderText(
    textType: "title" | "description",
    cocktailMenu: CocktailMenu,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    text: string,
    setText: React.Dispatch<React.SetStateAction<string>>,
    saveText: (options: { variables: MutationVars }) => void,
    renderedText: JSX.Element
) {
    const isTitle = textType === "title";
    const key = isTitle ? "saveCocktailMenuTitle" : "saveCocktailMenuDescription";

    return (
        <div className="flex-row-center full-width">
            {open ? (
                <TextField
                    color="secondary"
                    key={key}
                    defaultValue={text}
                    style={{width: "75%"}}
                    multiline={!isTitle}
                    onChange={(event) => setText(event.target.value)}
                />
            ) : (
                renderedText
            )}
            {open ? (
                <IconButton
                    color="secondary"
                    aria-label="Edit"
                    onClick={() => {
                        saveText({variables: {menuId: cocktailMenu.id, text}});
                        setOpen(false);
                    }}
                >
                    <Save/>
                </IconButton>
            ) : (
                <IconButton
                    color="secondary"
                    aria-label="Edit"
                    onClick={() => {
                        setText(isTitle ? cocktailMenu.name : cocktailMenu.description || "");
                        setOpen(true);
                    }}
                >
                    <Edit/>
                </IconButton>
            )}
        </div>
    );
}

function renderTitle(
    cocktailMenu: CocktailMenu,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    text: string,
    setText: React.Dispatch<React.SetStateAction<string>>,
    saveText: (options: { variables: MutationVars }) => void
) {
    return renderText(
        "title",
        cocktailMenu,
        open,
        setOpen,
        text,
        setText,
        saveText,
        <h3>{cocktailMenu.name}</h3>
    );
}

function renderDescription(
    cocktailMenu: CocktailMenu,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    text: string,
    setText: React.Dispatch<React.SetStateAction<string>>,
    saveText: (options: { variables: MutationVars }) => void
) {
    const renderedText = cocktailMenu.description ? (
        <div>{cocktailMenu.description}</div>
    ) : (
        <div style={{fontStyle: "italic"}}>{"No description added!"}</div>
    );
    return renderText("description",
        cocktailMenu,
        open,
        setOpen,
        text,
        setText,
        saveText, renderedText);
}

function renderCategoryDescriptions(
    cocktailMenu: CocktailMenu,
    categoryOpen: boolean,
    setCategoryOpen: React.Dispatch<React.SetStateAction<boolean>>,
    categoryState: { category: string; description: string }[] | null,
    setCategoryState: React.Dispatch<React.SetStateAction<any>>,
    saveCategory: (options: { variables: MutationVars }) => void
) {
    return (
        <Dialog open={categoryOpen}>
            <DialogContent>
                <div>
                    <h2>Category Descriptions</h2>
                    {cocktailMenu.categoryDescriptions.map(({category, description}) => (
                        <TextField
                            key={category}
                            label={category}
                            multiline
                            defaultValue={description}
                            onChange={(e) =>
                                setCategoryState((prevState) => [
                                    ...prevState!.map((cat) =>
                                        cat.category === category
                                            ? {...cat, description: e.target.value}
                                            : cat
                                    ),
                                ])
                            }
                        />
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        saveCategory({
                            variables: {
                                menuId: cocktailMenu.id,
                                categoryDescriptions: categoryState!,
                            },
                        });
                        setCategoryOpen(false);
                    }}
                >
                    Save
                </Button>
                <Button onClick={() => setCategoryOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
