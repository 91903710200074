import React from "react";
import "../CocktailMenu.css";
import CocktailMenuExternalUserFetcherInner from "./CocktailMenuExternalUserFetcherInner";
import {GET_DEFAULT_CACHE_DATA, getDefaultCacheData} from "../../ApolloLayer";
import {getServerUrl} from "../../utilities/IOUtilities";
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client";

const applicationCache = new InMemoryCache();
export let externalClient;
export default function CocktailMenuExternalUserFetcher(props) {
    externalClient = new ApolloClient({
        link: new HttpLink({uri: getServerUrl() + "/graphqlexternal"}),
        cache: applicationCache,
        resolvers: {}
    });
    applicationCache.writeQuery({
        query: GET_DEFAULT_CACHE_DATA,
        data: getDefaultCacheData()
    });
    return <ApolloProvider client={externalClient}>
        <CocktailMenuExternalUserFetcherInner/>
    </ApolloProvider>;
}