import React, {Component} from "react";
import {Menu} from "@material-ui/core";
import AmountCalculation from "./AmountCalculation";
import AutoCompleteField from "../AutoCompleteField";

class RecipeViewSearchBar extends Component {
    state = {
        mouseX: null,
        mouseY: null,
        chipIndex: undefined
    };

    handleRightClick(event, chipIndex) {
        // https://material-ui.com/components/menus/ - Context menu
        event.preventDefault();
        let chip = this.props.searchChips[chipIndex];
        if (chip.type === "Category" || chip.type === "Ingredient") {
            this.setState({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
                chipIndex: chipIndex
            });
        }
    }

    render() {
        const {dataSource, searchChips} = this.props;
        return <React.Fragment>
            {this.getAutocomplete(dataSource, searchChips)}
            <Menu
                open={this.state.mouseY !== null}
                anchorReference="anchorPosition"
                anchorPosition={
                    this.state.mouseY !== null && this.state.mouseX !== null
                        ? {top: this.state.mouseY, left: this.state.mouseX}
                        : undefined
                }
                onClose={() => {
                    this.setState({mouseX: null, mouseY: null});
                }}
            >
                <AmountCalculation cancel={() => {this.setState({mouseX: null, mouseY: null});}}
                                   chip={searchChips[this.state.chipIndex]}
                                   save={(ingredientFilter) => {
                                       let newSearchChips = JSON.parse(JSON.stringify(searchChips));
                                       newSearchChips[this.state.chipIndex]["minMaxAmount"] = ingredientFilter;
                                       this.props.onChange(newSearchChips);
                                       this.setState({mouseX: null, mouseY: null, chipIndex: undefined});
                                   }}/>
            </Menu>
        </React.Fragment>;
    }

    getAutocomplete(dataSource, searchChips) {
        return <AutoCompleteField dataSource={dataSource}
                                  searchChips={searchChips}
                                  handleRightClick={this.handleRightClick.bind(this)}
                                  onChange={this.props.onChange}/>;
    }
}

export default RecipeViewSearchBar;