import React, {useState} from "react";
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {SortableItem} from "./SortableItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import {AppBar, DialogContent, IconButton, List, Switch, Toolbar, Typography} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {
    INVERT_PRIORITIZE_INGREDIENT_USAGE,
    REORDER_PRIORITIZED_INGREDIENT_USAGES,
    TOGGLE_PRIORITIZED_INGREDIENT_USAGES
} from "../../utilities/Queries";
import {client} from "../../ApolloLayer";

type Props = {
    elements: Array<string>;
    open: boolean;
    onClose: () => void;
    enabled: boolean;
};

export const PrioritizedExhaustionIngredients = ({elements, open, onClose, enabled}: Props) => {
    const [invertPrioritizeIngredientUsage] = useMutation(INVERT_PRIORITIZE_INGREDIENT_USAGE, {
        client: client,
    });
    const [reorderList] = useMutation(REORDER_PRIORITIZED_INGREDIENT_USAGES, {
        client: client,
    });
    const [toggleEnabled] = useMutation(TOGGLE_PRIORITIZED_INGREDIENT_USAGES, {
        client: client,
    });
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    const [items, setItems] = useState(elements);
    const [elementsRemoved, setElementsRemoved] = useState<string[]>([]);
    const [isEnabled, setIsEnabled] = useState(enabled);

    const sensors = useSensors(
        useSensor(TouchSensor),
        useSensor(PointerSensor),
        useSensor(KeyboardSensor)
    );

    const handleDragEnd = ({active, over}: any) => {
        if (over && active.id !== over.id) {
            setItems((prevItems) => {
                const oldIndex = prevItems.indexOf(active.id);
                const newIndex = prevItems.indexOf(over.id);
                const result = arrayMove(prevItems, oldIndex, newIndex);
                reorderList({
                    variables: {
                        ingredients: result
                    }
                });
                if (!isEnabled) {
                    setIsEnabled(true);
                    toggleEnabled({variables: {enabled: true}});
                }
                return result;
            });
        }
    };

    const handleToggle = (item: string, isRemoved: boolean) => {
        setElementsRemoved((prev) => {
            if (isRemoved) {
                return prev.filter((el) => el !== item);
            } else {
                return [...prev, item];
            }
        });
        invertPrioritizeIngredientUsage({variables: {ingredient: item}});
    };

    const handleSwitchToggle = () => {
        const newEnabledState = !isEnabled;
        setIsEnabled(newEnabledState);
        toggleEnabled({variables: {enabled: newEnabledState}});
    };

    return (
        <Dialog open={open}
                fullScreen={!isLargeScreen}
                onClose={onClose}
        >
            {!isLargeScreen && (
                <AppBar color="secondary" style={{position: "relative"}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                            <Close color="primary"/>
                        </IconButton>
                        <Typography color="primary" variant="h6">
                            {"Your prioritized ingredients"}
                        </Typography>
                    </Toolbar>
                </AppBar>
            )}
            <DialogContent style={elements.length < 10 ? {overflow: "hidden"} : undefined}>
                <div style={{display: "flex", alignItems: "center", marginBottom: "8px", marginTop: "8px"}}>
                    <Typography variant="body1" style={{marginRight: "8px"}}>{"Enable ordering of prioritized ingredients"}</Typography>
                    <Switch
                        checked={isEnabled}
                        onChange={handleSwitchToggle}
                        color="secondary"
                    />
                </div>
                <Typography variant="body2">{"This will change the \"Exhaust prioritized ingredients \" sorting " +
                    "on the recipes page, ensuring that when a recipe uses the same prioritized ingredients, " +
                    "the top ingredients on this list will be first in the list."}</Typography>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={items} strategy={verticalListSortingStrategy}>
                        <List>
                            {items.map((item) => (
                                <SortableItem
                                    key={item}
                                    id={item}
                                    item={item}
                                    onToggle={handleToggle}
                                    isRemoved={elementsRemoved.includes(item)}
                                />
                            ))}
                        </List>
                    </SortableContext>
                </DndContext>
            </DialogContent>
        </Dialog>
    );
};