import React, {ChangeEvent, useState} from "react";
import CocktailMenuOverviewItem from "./CocktailMenuOverviewItem";
import List from "@material-ui/core/List";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CREATE_COCKTAIL_MENU, GET_COCKTAIL_MENUS} from "../CocktailMenuQueries";
import {client} from "../../ApolloLayer";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

// Define the types for the cocktail menu data
interface CocktailMenu {
    id: string;
    name: string;
}

// Define the structure of the query result
interface GetCocktailMenusData {
    cocktailMenus: CocktailMenu[];
}

// No specific variables are required for this query
interface GetCocktailMenusVars {
}

interface CreateCocktailMenuVars {
    name: string;
}

export default function CocktailMenus(): JSX.Element {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [newMenuText, setNewMenuText] = useState<string | null>(null);

    const [createMenu] = useMutation<void, CreateCocktailMenuVars>(CREATE_COCKTAIL_MENU, {
        client: client,
    });

    const {loading, error, data} = useQuery<GetCocktailMenusData, GetCocktailMenusVars>(GET_COCKTAIL_MENUS, {
        client: client,
    });

    if (loading) {
        return <CircularProgress color="secondary"/>;
    }

    if (error) {
        return (
            <div>
                <p>Error: {error.message}</p>
            </div>
        );
    }

    const menus = data?.cocktailMenus ?? [];

    return (
        <div className="flex-column-center">
            <h1>Select a menu</h1>
            <List>
                {menus.map((menu) => (
                    <CocktailMenuOverviewItem key={menu.id} menu={menu}/>
                ))}
                <ListItem
                    button
                    onClick={() => {
                        setCreateDialogOpen(true);
                    }}
                >
                    <ListItemText>{"Create new cocktail menu"}</ListItemText>
                </ListItem>
            </List>
            <Dialog
                onClose={() => {
                    setCreateDialogOpen(false);
                }}
                open={createDialogOpen}
            >
                <DialogContent>
                    <TextField
                        color="secondary"
                        style={{margin: "10px"}}
                        label="Menu name"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setNewMenuText(event.target.value);
                        }}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={!newMenuText || !newMenuText.trim()}
                        onClick={() => {
                            setCreateDialogOpen(false);
                            createMenu({
                                variables: {name: newMenuText as string},
                                refetchQueries: [
                                    {
                                        query: GET_COCKTAIL_MENUS,
                                    },
                                ],
                            });
                        }}
                    >
                        {"Create"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
