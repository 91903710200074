import React, {Component} from "react";
import "./SignInWithGoogle.css";
import {Paper, Typography} from "@material-ui/core";
import {GoogleLogin} from "@react-oauth/google";
import {jwtDecode} from "jwt-decode";

class SignInWithGoogle extends Component {

    handleLoginSuccess = (credentialResponse) => {
        const {credential} = credentialResponse;
        const decodedToken = jwtDecode(credential);
        this.props.loginSuccessful(decodedToken.sub);
    };

    render() {
        return <div className="outer">
            <Paper className="inner" elevation={4}>
                <div className="element">
                    <Typography variant="h5" component="h1">
                        Project Daiquiri
                    </Typography>
                </div>
                <div className="element">
                    <Typography component="p">
                        Welcome! Please login with Google to access the application.
                    </Typography>
                </div>
                <GoogleLogin onSuccess={this.handleLoginSuccess}
                             onFailure={() => console.log("Login failed")}/>
            </Paper>
        </div>;
    }
}

export default SignInWithGoogle;
