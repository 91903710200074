import React, {useEffect} from "react";
import SignInWithGoogle from "./SignInWithGoogle";
import {getFrontendUrl, isLocalHost} from "./IOUtilities";
import {getGoogleIdCookie} from "./Utilities";
import {useMutation} from "@apollo/react-hooks";
import {REGISTER_LOGIN} from "./Queries";
import {client} from "../ApolloLayer";

const LoginPage: React.FC = () => {
    const [registerLogin] = useMutation(REGISTER_LOGIN, {
        client: client,
    });

    // Helper function to generate cookie string
    const getCookieString = (googleId: string): string => {
        const CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 10);
        return `googleId=${googleId}; expires=${CookieDate.toUTCString()};`;
    };

    // Automatically set cookie and redirect on localhost if googleId is not set
    useEffect(() => {
        if (isLocalHost() && !getGoogleIdCookie()) {
            const googleId = "115587834512274835851"; // main
            document.cookie = getCookieString(googleId);
            window.location.href = getFrontendUrl();
        }
    }, []);

    return (
        <SignInWithGoogle
            loginSuccessful={(googleId: string) => {
                document.cookie = getCookieString(googleId);
                window.location.href = getFrontendUrl();
                registerLogin({
                    variables: {
                        id: googleId
                    }
                });
            }}
        />
    );
};

export default LoginPage;
