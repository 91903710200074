import React, {useReducer} from "react";
import "./MainView.css";
import Recipes from "./Recipes";
import FilterMenu from "./search/FilterMenu";
import Paper from "@material-ui/core/Paper";
import {Helmet} from "react-helmet";

export default function MainView(props) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    return <Paper style={{backgroundColor: "#f7f7f7", maxWidth: "960px", overflowX: "hidden", marginTop: "10px"}}
                  elevation={3}>
        <Helmet>
            <title>{"Project Daiquiri"}</title>
            <meta name="description" content="Search through thousands of cocktail and punch recipes from the best bars in the world.
                Create your own recipes and make cocktail menus for your own bar."/>
        </Helmet>
        <FilterMenu isLoggedIn={props.isLoggedIn}
                    forceUpdate={forceUpdate}/>
        <Recipes isLoggedIn={props.isLoggedIn}/>
    </Paper>;
}