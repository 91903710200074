import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_INGREDIENTS} from "../utilities/Queries";
import {CircularProgress, Paper} from "@material-ui/core";
import ExpandableIngredients from "./individualView/ExpandableIngredients";
import {client} from "../ApolloLayer";
import {makeStyles} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: "10px"
    },
    description: {
        marginBottom: "10px"
    },
    or: {
        marginTop: "50px",
        marginBottom: "50px"
    },
    paper: {
        backgroundColor: "#f7f7f7",
        marginTop: "25px"
    }
}));

export default function Ingredients() {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    const classes = useStyles();

    const {loading, error, data} = useQuery(GET_ALL_INGREDIENTS, {
        client: client
    });

    if (loading) {
        return (
            <div className="ingredients-outer">
                <div
                    style={{
                        height: "calc(100vh - 56px)",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <CircularProgress color="secondary"/>
                </div>
            </div>

        )
    }

    if (error) {
        console.log(error);
        return <div>error</div>;
    }

    const ingredients = JSON.parse(JSON.stringify(data.ingredients)).sort((ingredient1, ingredient2) => {
        return ingredient2.recipeCount.total - ingredient1.recipeCount.total;
    });

    let categories = {
        "Spirits": [],
        "Juices": [],
        "Liqueurs": [],
        "Syrups": [],
        "Bitters": [],
        "Wines": [],
        "Other": []
    };

    ingredients.forEach(ingredient => {
        let category = ingredient.name.split(",")[0];
        if (
            category === "Rum" ||
            category === "Whiskey" ||
            category === "Gin" ||
            category === "Vodka" ||
            category === "Tequila" ||
            category === "Mezcal" ||
            category === "Genever" ||
            category === "Brandy" ||
            category === "Aquavit" ||
            category === "Arrack" ||
            category === "Batavia Arrack" ||
            category === "Cachaça" ||
            category === "Eau de Vie" ||
            category === "Moonshine" ||
            category === "Sotol" ||
            category === "AU CHOIX" ||
            category === "Okolehao" ||
            category === "Grappa"
        ) {
            categories["Spirits"].push(ingredient);
        } else if (category === "Juice" || category === "Purée") {
            categories["Juices"].push(ingredient);
        } else if (category === "Liqueur" || category === "Amaro") {
            categories["Liqueurs"].push(ingredient);
        } else if (category === "Syrup" || category === "Nectar") {
            categories["Syrups"].push(ingredient);
        } else if (category === "Bitters") {
            categories["Bitters"].push(ingredient);
        } else if (
            category === "Wine" ||
            category === "Vermouth" ||
            category === "Sherry" ||
            category === "Lillet Blanc" ||
            category === "Cocchi Americano" ||
            category === "Lillet Rouge" ||
            category === "Lillet rosé" ||
            category === "Dubonnet"
        ) {
            categories["Wines"].push(ingredient);
        } else {
            categories["Other"].push(ingredient);
        }
    });

    let style = {height: "calc(100% - 25px)", paddingBottom: "20px"};
    if (isLargeScreen) {
        style.width = "960px";
    } else {
        style.width = "100%";
    }

    return (
        <div className="ingredients-outer">
            <Paper style={style} className={classes.paper} elevation={3}>
                <ExpandableIngredients
                    categories={categories}
                    prioritizedExhaustionIngredients={data.prioritizedExhaustionIngredients}
                    prioritizedUsageOrderingEnabled={data.prioritizedUsageOrderingEnabled}
                />
            </Paper>
        </div>
    );
}
