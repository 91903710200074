import {SORTING_STRATEGY_KEY} from "../recipes/search/SortingStrategy";

export function generateGuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function getGoogleIdCookie() {
    return getCookie("googleId");
}

function getCookie(name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        // @ts-ignore
        return parts.pop().split(";").shift();
    }
}

export function mapSearchTerms(searchTerms) {
    return searchTerms.map((term) => {
        return {
            text: term.name,
            type: term.type,
            inverted: term.inverted,
            minMaxAmount: term.minMaxAmount ? {
                minimum: term.minMaxAmount.minimum,
                maximum: term.minMaxAmount.maximum,
                typeOfMeasurement: term.minMaxAmount.typeOfMeasurement
            } : null
        };
    });
}

export function mapFilters(selectedSortingStrategy, selectedGlasses, minMaxIngredients, selectedBuildTypes, selectedTypes, selectedCocktailCategories, selectedBooks, minMaxABV, minMaxAlcoholicUnits) {
    return {
        selectedSortingStrategy: selectedSortingStrategy,
        selectedGlasses: selectedGlasses,
        selectedBuildTypes: selectedBuildTypes,
        selectedTypes: selectedTypes,
        selectedCocktailCategories: selectedCocktailCategories,
        selectedBooks: selectedBooks,
        minMaxIngredients: minMaxIngredients,
        minMaxABV: minMaxABV,
        minMaxAlcoholicUnits: minMaxAlcoholicUnits
    };
}


export function getPrettyIngredientName(name) {
    let split = name.split(", ");
    if (split.length === 2) {
        let category = split[0];
        let specific = split[1];
        if (category === "Fruit") {
            return specific;
        }
        if (category === "Amaro") {
            if (specific === "Averna" || specific === "Montenegro" || specific === "Nonino" || specific === "Bràulio") {
                return category + " " + specific;
            }
            if (specific === "Angostura") {
                return "Amaro di Angostura";
            }
            return specific;
        }
        if (category === "Spice") {
            return specific;
        }
        if (category === "Liqueur") {
            if (specific === "Falernum" || specific === "Green Chartreuse" || specific === "Green Chartreuse V.E.P." || specific === "Yellow Chartreuse" ||
                specific === "Absinthe" || specific === "Bénédictine") {
                return specific;
            }
        }
        if (category === "Brandy") {
            if (specific === "Cognac") {
                return specific;
            }
        }
        if (category === "Whiskey") {
            if (specific === "Bourbon") {
                return specific;
            }
        }
        if (category === "Egg") {
            if (specific === "Whole") {
                return category + " (" + specific + ")";
            }
            return category + " " + specific;
        }
        if (category === "Beer") {
            if (specific === "Ale") {
                return specific;
            }
        }
        if (category === "Syrup") {
            if (specific === "Grenadine" || specific === "Orgeat") {
                return specific;
            }
        }
        return specific + " " + category;
    }
    return name;
}