import React, {Component} from "react";
import "./App.css";
import {getServerUrl} from "./utilities/IOUtilities";
import {getGoogleIdCookie} from "./utilities/Utilities";
import {
    MAX_ABV,
    MAX_ALCOHOLIC_UNITS,
    MAX_INGREDIENTS,
    MIN_ABV,
    MIN_ALCOHOLIC_UNITS,
    MIN_INGREDIENTS
} from "./recipes/search/AdvancedFilters";
import MainView from "./recipes/MainView";
import {Route, Routes} from "react-router-dom";
import FullRecipe from "./recipes/FullRecipe";
import TopBar from "./TopBar";
import Ingredients from "./ingredients/Ingredients";
import CocktailMenus from "./cocktailmenu/overview/CocktailMenus";
import CocktailMenuEditor from "./cocktailmenu/CocktailMenuEditor";
import LoginPage from "./utilities/LoginPage";
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client";
import gql from "graphql-tag";

const applicationCache = new InMemoryCache();
export let client;

class ApolloLayer extends Component {

    render() {
        applicationCache.writeQuery({
            query: GET_DEFAULT_CACHE_DATA,
            data: getDefaultCacheData()
        });
        if (!getGoogleIdCookie()) {
            client = new ApolloClient({
                link: new HttpLink({uri: getServerUrl() + "/graphqlexternal"}),
                cache: applicationCache,
                resolvers: {}
            });
            return <ApolloProvider client={client}>
                <div className="flex-column-center">
                    <TopBar isLoggedIn={false}/>
                    <Routes>
                        <Route path="/book/:s/:s" element={<FullRecipe isLoggedIn={false}/>}/>
                        <Route path="/" element={<MainView isLoggedIn={false}/>}/>
                        <Route path="/recipes" element={<MainView isLoggedIn={false}/>}/>

                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="*" element={<div>This route doesn't exist, 404.</div>}/>
                    </Routes>
                </div>
            </ApolloProvider>;
        }
        let googleIdCookie = "googleId=" + getGoogleIdCookie();
        let uri = getServerUrl() + "/graphql?" + googleIdCookie;
        client = new ApolloClient({
            link: new HttpLink({uri: uri}),
            cache: applicationCache,
            resolvers: {}
        });

        return <ApolloProvider client={client}>
            <div className="flex-column-center">
                <TopBar isLoggedIn={true}/>
                <Routes>
                    <Route path="/book/:s" element={<MainView isLoggedIn={true}/>}/>
                    <Route path="/book/:s/:s" element={<FullRecipe isLoggedIn={true}/>}/>
                    <Route path="/" element={<MainView isLoggedIn={true}/>}/>
                    <Route path="/menus" element={<CocktailMenus/>}/>
                    <Route path="/CocktailMenu/:s/" element={<CocktailMenuEditor/>}/>
                    <Route path="/recipes" element={<MainView isLoggedIn={true}/>}/>
                    <Route path="/ingredients" element={<Ingredients/>}/>
                    <Route path="*" element={<div>This route doesn't exist, 404.</div>}/>
                </Routes>
            </div>
        </ApolloProvider>;
    }
}

export const GET_DEFAULT_CACHE_DATA = gql`
  query GetDefaultCacheData {
    searchTerms
    selectedSortingStrategy
    selectedGlasses {
      __typename
      data
      key
    }
    selectedBuildTypes {
      __typename
      data
      key
    }
    selectedTypes {
      __typename
      data
      key
    }
    selectedCocktailCategories {
      __typename
      data
      key
    }
    selectedBooks {
      __typename
      data
      key
    }
    minMaxIngredients
    minMaxABV
    minMaxAlcoholicUnits
  }
`;

export function getDefaultCacheData() {
    return {
        searchTerms: [],
        selectedSortingStrategy: null,
        selectedGlasses: {
            __typename: "selectedGlasses",
            data: [],
            key: 0
        },
        selectedBuildTypes: {
            __typename: "selectedBuildTypes",
            data: [],
            key: 0
        },
        selectedTypes: {
            __typename: "selectedTypes",
            data: ["Cocktail"],
            key: 0
        },
        selectedCocktailCategories: {
            __typename: "selectedCocktailCategories",
            data: [],
            key: 0
        },
        selectedBooks: {
            __typename: "selectedBooks",
            data: [],
            key: 0
        },
        minMaxIngredients: [MIN_INGREDIENTS, MAX_INGREDIENTS],
        minMaxABV: [MIN_ABV, MAX_ABV],
        minMaxAlcoholicUnits: [MIN_ALCOHOLIC_UNITS, MAX_ALCOHOLIC_UNITS]
    };
}

export default ApolloLayer;
