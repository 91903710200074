import gql from "graphql-tag";

export const RECIPE_OVERVIEW_DATA = gql`
  fragment RecipeOverviewData on Recipe {
    id
    name
    book {
        name
        formattedName
    }
    ingredients {
        id
        name
    }
    canMake
    linkedRecipes {
        id
        name
        book {
            name
            formattedName
        }
        pageNumber
    }
    favorite
    pageNumber
  }
`;

export const LOAD_SEARCH_TERMS = gql`
{
  searchTerms @client {
    id
    name
    type
    inverted
    minMaxAmount {
        minimum
        maximum
        typeOfMeasurement
    }
  }
  selectedSortingStrategy
  selectedGlasses {
    data
    key
  }
  selectedBuildTypes {
    data
    key
  }
  selectedTypes {
    data
    key
  }
  selectedCocktailCategories {
    data
    key
  }
  selectedBooks {
    data
    key
  }
  minMaxIngredients
  minMaxABV
  minMaxAlcoholicUnits
}
`;

export const ON_FAVORITE = gql`
  mutation onFavorite($id: String!, $favorite: Boolean!) {
    setFavorite(id: $id, favorite: $favorite) {
      id
      favorite
    }
  }
`;

export const GET_RECIPES = gql`
query GET_RECIPES($filters: [Filter!], $advancedFilters: AdvancedFilters, $offset: Int!, $limit: Int!) {
  recipes(filters: $filters, advancedFilters: $advancedFilters, offset: $offset, limit: $limit) {
    ...RecipeOverviewData
  }
  recipesLength(filters: $filters, advancedFilters: $advancedFilters, offset: $offset, limit: $limit)
}
  ${RECIPE_OVERVIEW_DATA}
`;

export const GET_SEARCH_ITEMS_AND_FILTER_DATA = gql`
query GET_SEARCH_ITEMS {
  searchItems {
    text
    type
  }
  glasses
  buildTypes
  types
  cocktailCategories
  books {
    name
  }
  sortingStrategies
  preferredSortingStrategy
}
`;

export const GET_RECIPE_FULL = gql`
query GET_RECIPE_FULL($bookFormattedName: String!, $nameWithoutSpaces: String!) {
  recipe(bookFormattedName: $bookFormattedName, nameWithoutSpaces: $nameWithoutSpaces) {
    id
    name
    instructions
    comment
    postDilutionAbv
    alcoholicUnits
    postDilutionVolume
    cocktailCategory
    ingredients {
        id
        name
        amount
        typeOfMeasurement
        bottles
    }
    garnish
    glass
    pageNumber
    servings
    tags {
        id
        name
    }
    book {
        name
        formattedName
    }
  }
  tags {
    id
    name
  }
}
`;

export const DELETE_TAG = gql`
  mutation deleteTag($recipeId: String!, $tag: String!) {
    deleteTag(recipeId: $recipeId, tag: $tag) {
        id
        tags {
            id
            name
        }
    }
  }
`;

export const ADD_TAG = gql`
  mutation addTag($recipeId: String!, $tag: String!) {
    addTag(recipeId: $recipeId, tag: $tag) {
        id
        tags {
            id
            name
        }
    }
  }
`;

export const SAVE_COMMENT = gql`
  mutation saveComment($recipeId: String!, $comment: String!) {
    saveComment(recipeId: $recipeId, comment: $comment) {
        id
        comment
    }
  }
`;

export const GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH = gql`
query GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH($search: String) {
  searchRecipes(search: $search) {
    id
    name
    book {
        name
    }
  }
}
`;

export const GET_ALL_INGREDIENTS = gql`
query GET_ALL_INGREDIENTS {
  ingredients {
    id
    name
    recipeCount {
        total
        canMake
        lastIngredientMissing
    }
    owned
    prioritized
    bottles {
      id
      name
      numberOfRecipes
    }
  }
  prioritizedExhaustionIngredients
  prioritizedUsageOrderingEnabled
}
`;

export const ON_CAN_MAKE = gql`
  mutation ON_CAN_MAKE($id: String!) {
    canMake(id: $id) {
        id
        canMake
    }
  }
`;

export const ON_REMOVE_INGREDIENT = gql`
  mutation ON_REMOVE_INGREDIENT($id: String!, $ingredient: String!) {
    removeIngredient(id: $id, ingredient: $ingredient) {
        id
        canMake
    }
  }
`;

export const INVERT_INGREDIENT = gql`
  mutation INVERT_INGREDIENT($ingredient: String!) {
    invertIngredient(ingredient: $ingredient)
  }
`;

export const INVERT_PRIORITIZE_INGREDIENT_USAGE = gql`
  mutation INVERT_PRIORITIZE_INGREDIENT_USAGE($ingredient: String!) {
    invertPrioritizeIngredientUsage(ingredient: $ingredient)
  }
`;

export const REORDER_PRIORITIZED_INGREDIENT_USAGES = gql`
  mutation REORDER_PRIORITIZED_INGREDIENT_USAGES($ingredients: [String!]!) {
    reorderPrioritizedIngredientUsages(ingredients: $ingredients)
  }
`;

export const TOGGLE_PRIORITIZED_INGREDIENT_USAGES = gql`
  mutation TOGGLE_PRIORITIZED_INGREDIENT_USAGES($enabled: Boolean!) {
    setPrioritizedUsageOrderingEnabled(enabled: $enabled)
  }
`;

export const REGISTER_LOGIN = gql`
  mutation REGISTER_LOGIN($id: String!) {
    registerLogin(id: $id)
  }
`;

export const SET_PREFERRED_SORTING_STRATEGY = gql`
  mutation SET_PREFERRED_SORTING_STRATEGY($sortingStrategy: String!) {
    setPreferredSortingStrategy(sortingStrategy: $sortingStrategy)
  }
`;
