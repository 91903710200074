import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, IconButton, List, Typography} from "@material-ui/core";
import {ExpandMore, Stars} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import SingleIngredient from "./SingleIngredient";
import {PrioritizedExhaustionIngredients} from "./PrioritizedExhaustionIngredients";

export default function ExpandableIngredients(props) {
    const [expanded, setExpanded] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [displayPrioritizedExhaustionIngredients, setDisplayPrioritizedExhaustionIngredients] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const categories = props.categories;
    let filteredCategories = {};
    Object.keys(categories).forEach((category) => {
        let list = categories[category];
        list = list.filter((ingredient) => {
            let lowerCaseIngredient = ingredient.name.toLowerCase();
            let split = lowerCaseIngredient.split(", ");
            return (lowerCaseIngredient.startsWith(search) || (split[1] && split[1].startsWith(search)) || (split[1] && (split[1] + " " + split[0]).startsWith(search)));
        });
        if (list.length > 0) {
            filteredCategories[category] = list;
        }
    });
    const prioritizedExhaustionIngredients = props.prioritizedExhaustionIngredients;
    return (
        <div className="flex-column margin-above margin-below">
            <div className={"flex-row-align-center margin-above margin-below margin-left margin-right"}>
                <TextField color="secondary"
                           style={{marginRight: "8px", width: "calc(100% - 58px)"}}
                           label="Search"
                           onChange={(event) => {
                               setSearch(event.target.value.toLowerCase());
                           }}
                           variant="outlined"/>
                <IconButton edge="end" disabled={prioritizedExhaustionIngredients.length === 0} onClick={() => {
                    setDisplayPrioritizedExhaustionIngredients(true);
                }} aria-label="delete">
                    <Stars color={prioritizedExhaustionIngredients.length > 0 ? "secondary" : "inherit"}/>
                </IconButton>
            </div>
            {Object.keys(filteredCategories).map(category => {
                let list = filteredCategories[category];

                let isExpanded = expanded === category;
                return <Accordion expanded={isExpanded} onChange={handleChange(category)} key={category}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                    >
                        <Typography>{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isExpanded && <List style={{width: "100%"}}>
                            {list.map((ingredient) => {
                                return <SingleIngredient key={ingredient.id} ingredient={ingredient}/>;

                            })}
                        </List>}
                    </AccordionDetails>
                </Accordion>;
            })}
            <PrioritizedExhaustionIngredients elements={prioritizedExhaustionIngredients}
                                              enabled={props.prioritizedUsageOrderingEnabled}
                                              open={displayPrioritizedExhaustionIngredients}
                                              onClose={() => setDisplayPrioritizedExhaustionIngredients(false)}/>
        </div>
    );
}