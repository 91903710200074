export function isLocalHost() {
    return window.location.href.startsWith("http://localhost:");
}

export function getServerUrl() {
    return isLocalHost() ? ("http://localhost:8080") : ("https://cocktail-guide-server.herokuapp.com")
}

export function getFrontendUrl() {
    return isLocalHost() ? ("http://localhost:3000") : ("http://www.projectdaiquiri.com")
}