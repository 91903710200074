import React from "react";
import {DialogContent} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";

export default function BottlesView(props) {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    const ingredient = props.ingredient;

    let content = renderContent(ingredient);

    return <Dialog open={true} fullScreen={!isLargeScreen} onClose={() => {
        props.close();
    }}>
        {!isLargeScreen && <AppBar color="secondary" style={{position: "relative"}}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => {
                    props.close();
                }} aria-label="close">
                    <Close color="primary"/>
                </IconButton>
                <Typography color="primary" variant="h6">
                    {"Suggested bottles for " + ingredient.name}
                </Typography>
            </Toolbar>
        </AppBar>}
        <DialogContent>
            {content}
        </DialogContent>
    </Dialog>;
}

function renderContent(ingredient) {
    const sortedBottles = ingredient.bottles.sort((bottle1, bottle2) => {
        return bottle2.numberOfRecipes - bottle1.numberOfRecipes
    });
    return <div className="flex-column">
        {sortedBottles.map((bottle) => {
            return <Typography key={bottle.id}
                               variant="body2">{bottle.name + " (used in " + bottle.numberOfRecipes + " recipes)"}</Typography>;
        })}
    </div>;
}