import React from "react";
import {LIMIT} from "./RecipesInner";
import Skeleton from "@material-ui/lab/Skeleton";

const buttonWidth = 24;
const buttonPadding = 12;

export default function RecipesLoading(props) {
    let skeletons: any[] = [];
    for (let i = 0; i < LIMIT; i++) {
        skeletons.push(
            <div className="recipe-card" key={"skeleton-" + i} style={{marginBottom: "10px"}}>
                <div style={{padding: "16px"}}>
                    <Skeleton variant="text" height={32}/>
                    <Skeleton variant="text" height={24}/>
                </div>
                <div style={{padding: "16px"}}>
                    <Skeleton variant="rect" width={268} height={74}/>
                </div>
                <div className="flex-row" style={{padding: "16px"}}>
                    {renderButton()}
                    {renderButton()}
                    {renderButton()}
                    {renderButton()}
                </div>
            </div>
        );
    }
    return <div className="recipe-cards">
        {skeletons}
    </div>;
}

function renderButton() {
    return <Skeleton variant="circle" width={buttonWidth} height={buttonWidth}
                     style={{margin: buttonPadding}}/>;
}