import React, {useEffect, useState} from "react";
import {Slider} from "@material-ui/core";
import {client} from "../../ApolloLayer";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    slider: {
        width: "60%"
    }
}));

export default function SliderFilterComponent(props) {
    const classes = useStyles();
    const [localValue, setLocalValue] = useState([...props.value]); // Copy props.value to prevent mutation

    useEffect(() => {
        setLocalValue([...props.value]); // Ensure the local state stays in sync on filter reset
    }, [props.value]);

    return (
        <Slider
            className={classes.slider}
            color="secondary"
            value={localValue}
            step={props.step}
            onChange={(event, newValue) => {
                // @ts-ignore
                setLocalValue([...newValue]);
            }}
            onChangeCommitted={(event, newValue) => {
                client.cache.modify({
                    id: `ROOT_QUERY`,
                    fields: {
                        [props.cacheName](existingData = []) {
                            // @ts-ignore
                            return [...newValue];
                        },
                    },
                });
            }}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            valueLabelFormat={(value, index) => {
                if (index === 1 && value === props.max) {
                    return value + "+";
                }
                return value;
            }}
            min={props.min}
            max={props.max}
            marks={props.marks}
        />
    );
}