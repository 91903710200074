import React from "react";
import "./FullRecipe.css";
import Typography from "@material-ui/core/Typography";
import {renderComments, renderIngredients, renderInstructions, renderTags} from "./FullRecipeContent";
import {makeStyles} from "@material-ui/core/styles";
import {renderAbvCard, renderCocktailCategoryCard, renderUnitsCard} from "./RedesignFullRecipeLarge";

const useStyles = makeStyles(theme => ({
    card: {
        width: "250px"
    },
    ingredients: {
        marginBottom: "10px"
    },
    ingredientsExtras: {
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        fontStyle: "italic"
    },
    ingredientAmount: {
        width: "33%"
    },
    avatarPersonal: {
        backgroundColor: "inherit",
        color: "#d4af37"
    },
    chip: {
        margin: "5px"
    },
    commentsDialog: {
        width: "400px"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: "inherit"
    }
}));

export default function RedesignFullRecipeMobile(props) {
    const recipe = props.recipe;
    const allTags = props.allTags;
    const classes = useStyles();

    const [value, setValue] = React.useState(0);

    return <div className="flex-column-center">
        <div>
            <div className="title-and-book">
                <Typography variant="h5" style={{textAlign: "center"}} color="secondary">
                    {recipe.name}
                </Typography>
                <div className="flex-column-center">
                    <Typography variant="h6" color="secondary">
                        {recipe.book.name}
                    </Typography>
                    <Typography variant="h6" color="secondary">
                        {"Page " + recipe.pageNumber + ""}
                    </Typography>
                </div>
            </div>
            {renderIngredients(recipe, classes, true)}
            <div>
                {renderInstructions(recipe, classes)}
                {renderComments(recipe, classes, props.saveComment, props.getRecipeFullVariables, true, props.isLoggedIn)}
                {renderTags(recipe, props.deleteTag, props.addTag, props.getRecipeFullVariables, classes, allTags, true, props.isLoggedIn)}
            </div>
            <div className="flex-column-center">
                <div className="mobile-card">
                    {renderAbvCard(recipe, classes)}
                </div>
                <div className="mobile-card">
                    {renderUnitsCard(recipe, classes)}
                </div>
                <div className="mobile-card">
                    {renderCocktailCategoryCard(recipe, classes)}
                </div>
            </div>
        </div>
    </div>;
}