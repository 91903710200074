import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {IconButton, ListItem, ListItemText} from "@material-ui/core";
import {DragIndicator, StarBorderOutlined, StarOutlined} from "@material-ui/icons";

type SortableItemProps = {
    id: string;
    item: string;
    onToggle: (item: string, isRemoved: boolean) => void;
    isRemoved: boolean;
};

export const SortableItem = ({id, item, onToggle, isRemoved}: SortableItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <ListItem ref={setNodeRef} style={style}>
            <IconButton {...attributes} {...listeners} edge="start"
                        style={{
                            touchAction: "none" // Prevent touch gestures like scrolling from interfering
                        }}>
                <DragIndicator/>
            </IconButton>
            <ListItemText primary={item}/>
            <IconButton
                edge="end"
                aria-label="Remove prioritized usage"
                onClick={() => onToggle(item, isRemoved)}
            >
                {!isRemoved ? <StarOutlined color="secondary"/> : <StarBorderOutlined color="inherit"/>}
            </IconButton>
        </ListItem>
    );
};
