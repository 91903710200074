import React from "react";
import "../CocktailMenu.css";
import CocktailMenuItem from "./CocktailMenuItem";

export default function CocktailMenuRenderer(props) {
    let menu = props.menu;
    let categories = calculateCategories(menu);
    let pages = calculatePages(categories);

    return <div className="flex-column-center">
        {renderFirstPage(menu, pages)}
        {renderOtherPages(menu, pages)}
    </div>;
}


function calculateCategories(menu) {
    let items = menu.cocktailMenuItems;
    let usedCategories: any[] = [];
    let list: any[] = [];
    items.forEach((item, i) => {
        if (item.category) {
            if (!usedCategories.includes(item.category)) {
                let itemsInThisCategory: any[] = [];
                itemsInThisCategory.push(item);
                items.forEach((otherItem, j) => {
                    if (j > i && otherItem.category === item.category) {
                        itemsInThisCategory.push(otherItem);
                    }
                });
                usedCategories.push(item.category);
                list.push(itemsInThisCategory);
            }
        } else {
            list.push(item);
        }
    });
    return list;
}

function calculatePages(categories) {
    let pages: any[] = [];
    let usedSingleItems: any[] = [];
    categories.forEach((category, i) => {
        if (Array.isArray(category)) {
            pages.push(category);
        } else if (!usedSingleItems.includes(category.name)) {
            let itemsBeforeCategory: any[] = [];
            itemsBeforeCategory.push(category);
            usedSingleItems.push(category.name);
            for (let j in categories) {
                if (j > i) {
                    let otherCategory = categories[j];
                    if (Array.isArray(otherCategory)) {
                        break;
                    }
                    itemsBeforeCategory.push(otherCategory);
                    usedSingleItems.push(otherCategory.name);
                }
            }
            pages.push(itemsBeforeCategory);
        }
    });
    return pages;
}

function renderItems(menu, items, i) {
    let category = items[0] ? items[0].category : undefined;
    let categoryDescription = undefined;
    if (category) {
        let find = menu.categoryDescriptions.find((catDesc) => {return catDesc.category === category});
        if (find) {
            categoryDescription = find.description;
        }
    }
    return <div key={"page-" + i} className="cocktail-menu-items">
        {category && <div className="flex-column-center" style={{marginBottom: "10px"}}>
            <h2 style={{marginBottom: "10px"}}>{category}</h2>
            {categoryDescription && <div style={{fontStyle: "italic"}}>{categoryDescription}</div>}
        </div>}
        {items.map((cocktailMenuItem, j) => {
            return <CocktailMenuItem key={"menu-item-page-" + i + j} item={cocktailMenuItem}/>;

        })}
    </div>;
}

function renderFirstPage(menu, pages) {
    let page = [];
    if (pages.length > 0) {
        page = pages[0];
    }
    return <div className="cocktail-menu">
        <div className="cocktail-menu-title">{menu.name}</div>
        {menu.description && <div className="cocktail-menu-description">{menu.description}</div>}
        {renderItems(menu, page, 0)}
    </div>;
}

function renderOtherPages(menu, allPages) {
    if (allPages.length < 2) {
        return undefined;
    }
    let pages = allPages.slice(1);
    return pages.map((page, i) => (
        <div className="cocktail-menu" key={"cocktail-menu-" + (i + 1)}>
            {renderItems(menu, page, i + 1)}
        </div>
    ));
}