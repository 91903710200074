import React from "react";
import {IconButton, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {CheckCircle, InfoOutlined, StarBorderOutlined, StarOutlined} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {GET_ALL_INGREDIENTS, INVERT_INGREDIENT, INVERT_PRIORITIZE_INGREDIENT_USAGE} from "../../utilities/Queries";
import Divider from "@material-ui/core/Divider";
import {client} from "../../ApolloLayer";
import BottlesView from "./BottlesView";

export default function SingleIngredient(props) {
    const [displayPossibleBottles, setDisplayPossibleBottles] = React.useState(false);

    const [invertIngredient] = useMutation(INVERT_INGREDIENT, {
        client: client
    });
    const [invertPrioritizeIngredientUsage] = useMutation(INVERT_PRIORITIZE_INGREDIENT_USAGE, {
        client: client
    });

    let ingredient = props.ingredient;
    let listItemText;
    if (ingredient.owned) {
        listItemText = ingredient.name + " (Used in " + ingredient.recipeCount.total
            + " recipes and " + ingredient.recipeCount.canMake
            + " recipes you can make)";
    } else {
        listItemText = ingredient.name + " (Used in " + ingredient.recipeCount.total
            + " recipes and finishes " + ingredient.recipeCount.lastIngredientMissing
            + " recipes)";
    }
    return <React.Fragment>
        <ListItem>
            <ListItemText>{listItemText}</ListItemText>

            {ingredient.bottles.length > 0 &&
                <ListItemIcon style={{minWidth: 0}}>
                    <IconButton edge="end" onClick={() => {
                        setDisplayPossibleBottles(ingredient);
                    }} aria-label="delete">
                        <InfoOutlined/>
                    </IconButton>
                </ListItemIcon>}
            {ingredient.owned && <ListItemIcon style={{minWidth: 0}}>
                <IconButton edge="end" onClick={() => {
                    invertPrioritizeIngredientUsage({
                        variables: {"ingredient": ingredient.name},
                        refetchQueries: [{
                            query: GET_ALL_INGREDIENTS
                        }]
                    });
                }} aria-label="delete">
                    {ingredient.prioritized ? <StarOutlined color={"secondary"}/> :
                        <StarBorderOutlined color={"inherit"}/>}
                </IconButton>
            </ListItemIcon>}
            <ListItemIcon style={{minWidth: 0}}>
                <IconButton edge="end" onClick={() => {
                    invertIngredient({
                        variables: {"ingredient": ingredient.name},
                        refetchQueries: [{
                            query: GET_ALL_INGREDIENTS
                        }]
                    });
                }}>
                    <CheckCircle color={ingredient.owned ? "secondary" : "inherit"}/>
                </IconButton>
            </ListItemIcon>
        </ListItem>
        <Divider/>
        {displayPossibleBottles &&
            <BottlesView ingredient={ingredient} close={() => setDisplayPossibleBottles(false)}/>}
    </React.Fragment>;
}