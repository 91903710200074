import React, {JSX, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_SEARCH_ITEMS_AND_FILTER_DATA, LOAD_SEARCH_TERMS} from "../../utilities/Queries";
import {makeStyles} from "@material-ui/core/styles";
import {client, GET_DEFAULT_CACHE_DATA, getDefaultCacheData} from "../../ApolloLayer";
import {Button, Collapse, Paper} from "@material-ui/core";
import AdvancedFilters, {
    MAX_ABV,
    MAX_ALCOHOLIC_UNITS,
    MAX_INGREDIENTS,
    MIN_ABV,
    MIN_ALCOHOLIC_UNITS,
    MIN_INGREDIENTS
} from "./AdvancedFilters";
import {Tune} from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import RecipeViewSearchBar from "./RecipeViewSearchBar";
import gql from "graphql-tag";

const useStyles = makeStyles(theme => ({
    paper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FFF",
        color: "white",
        minHeight: "60px",
        paddingRight: "10px",
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    mobilePaper: {
        display: "flex",
        flexDirection: "column",
        padding: "10px"
    },
    button: {
        color: "white"
    },
    filterMenuContent: {
        backgroundColor: "#FFF",
        marginTop: "5px",
    }
}));

export default function FilterMenu(props): JSX.Element {
    const classes = useStyles();
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);
    const {
        data: {
            searchTerms,
            selectedSortingStrategy,
            selectedGlasses,
            selectedBuildTypes,
            selectedTypes,
            selectedCocktailCategories,
            selectedBooks,
            minMaxIngredients,
            minMaxABV,
            minMaxAlcoholicUnits
        }
    } = useQuery(LOAD_SEARCH_TERMS, {
        client: client
    });
    const {loading, error, data} = useQuery(GET_SEARCH_ITEMS_AND_FILTER_DATA, {
        client: client
    });

    let searchChips = [];
    let glasses = [];
    let buildTypes = [];
    let types = [];
    let cocktailCategories = [];
    let books = [];
    let sortingStrategies = [];
    if (error) {
        console.log(error);
        return <div>{error.message}</div>
    }
    if (!loading) {
        searchChips = data.searchItems;
        glasses = data.glasses;
        buildTypes = data.buildTypes;
        types = data.types;
        cocktailCategories = data.cocktailCategories;
        books = data.books.map((book) => {
            return book.name;
        });
        sortingStrategies = data.sortingStrategies;
    }

    const isLargeScreen = useMediaQuery("(min-width:950px)");

    return (
        <div className="filter-menu">
            <Paper className={isLargeScreen ? classes.paper : classes.mobilePaper}>
                <RecipeViewSearchBar dataSource={searchChips}
                                     maxSearchResults={10}
                                     hintText="Search for recipes or ingredients..."
                                     searchChips={searchTerms}
                                     selectedSortingStrategy={selectedSortingStrategy}
                                     selectedGlasses={selectedGlasses.data}
                                     selectedBuildTypes={selectedBuildTypes.data}
                                     selectedTypes={selectedTypes.data}
                                     selectedCocktailCategories={selectedCocktailCategories.data}
                                     selectedBooks={selectedBooks.data}
                                     key={selectedGlasses.key + "" + selectedBuildTypes.key + "" + selectedTypes.key + "" + selectedBooks.key + "" + selectedCocktailCategories.key}
                                     minMaxIngredients={minMaxIngredients}
                                     minMaxABV={minMaxABV}
                                     minMaxAlcoholicUnits={minMaxAlcoholicUnits}
                                     onChange={(newSearchTerms) => {
                                         props.forceUpdate();
                                         client.writeQuery({
                                             query: GET_SEARCH_TERMS,
                                             data: {searchTerms: newSearchTerms}
                                         });
                                     }}
                                     isOpen={isSearchMenuOpen}
                                     setIsOpen={setIsSearchMenuOpen}
                />
                <div className={isLargeScreen ? "filter-button-div-large" : "filter-button-div-small"}>
                    <Button
                        disabled={!hasFiltersChangedFromDefault(searchTerms, selectedGlasses.data, selectedBuildTypes.data, selectedTypes.data, selectedCocktailCategories.data, selectedBooks.data, minMaxIngredients, minMaxABV, minMaxAlcoholicUnits)}
                        variant="outlined"
                        color="secondary"
                        startIcon={<Tune/>}
                        style={{marginRight: "5px"}}
                        onClick={() => {
                            setIsFilterMenuOpen(false);
                            client.writeQuery({query: GET_DEFAULT_CACHE_DATA, data: getDefaultCacheData()});
                        }}>
                        {"Reset"}
                    </Button>
                    <Button
                        disabled={loading}
                        variant="contained"
                        className={classes.button}
                        color="secondary"
                        startIcon={<Tune/>}
                        onClick={() => {
                            setIsFilterMenuOpen(!isFilterMenuOpen);
                        }}>
                        {"Filter"}
                    </Button>
                </div>
            </Paper>
            <Collapse in={isFilterMenuOpen}>
                <Paper className={classes.filterMenuContent}>
                    <AdvancedFilters glasses={glasses}
                                     selectedGlasses={selectedGlasses.data}
                                     buildTypes={buildTypes}
                                     selectedBuildTypes={selectedBuildTypes.data}

                                     types={types}
                                     selectedTypes={selectedTypes.data}

                                     cocktailCategories={cocktailCategories}
                                     selectedCocktailCategories={selectedCocktailCategories.data}
                                     preferredSortingStrategy={data?.preferredSortingStrategy}
                                     sortingStrategies={sortingStrategies}
                                     selectedSortingStrategy={selectedSortingStrategy}
                                     books={books}
                                     selectedBooks={selectedBooks.data}
                                     minMaxIngredients={minMaxIngredients}
                                     minMaxABV={minMaxABV}
                                     minMaxAlcoholicUnits={minMaxAlcoholicUnits}
                                     forceUpdate={props.forceUpdate}
                                     key={selectedGlasses.key + "" + selectedBuildTypes.key + "" + selectedTypes.key + "" + selectedBooks.key + "" + selectedCocktailCategories.key}
                    />
                </Paper>
            </Collapse>
        </div>
    );
};

function hasFiltersChangedFromDefault(searchTerms, selectedGlasses, selectedBuildTypes, selectedTypes, selectedCocktailCategories, selectedBooks, minMaxIngredients, minMaxABV, minMaxAlcoholicUnits) {
    if (searchTerms.length > 0) {
        return true;
    }
    if (selectedGlasses.length > 0) {
        return true;
    }
    if (selectedBuildTypes.length > 0) {
        return true;
    }
    if (selectedTypes.length !== 1 || selectedTypes[0] !== "Cocktail") {
        return true;
    }
    if (selectedCocktailCategories.length > 0) {
        return true;
    }
    if (selectedBooks.length > 0) {
        return true;
    }
    if (minMaxIngredients[0] !== MIN_INGREDIENTS || minMaxIngredients[1] !== MAX_INGREDIENTS) {
        return true;
    }
    if (minMaxABV[0] !== MIN_ABV || minMaxABV[1] !== MAX_ABV) {
        return true;
    }
    if (minMaxAlcoholicUnits[0] !== MIN_ALCOHOLIC_UNITS || minMaxAlcoholicUnits[1] !== MAX_ALCOHOLIC_UNITS) {
        return true;
    }
    return false;
}

const GET_SEARCH_TERMS = gql`
  query GetSearchTerms {
    searchTerms
  }
`;